export const Linkedin = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="socialIcon"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.95723e-07 2.67346C3.95723e-07 1.96441 0.281667 1.28441 0.783037 0.78304C1.28441 0.28167 1.96441 3.56409e-06 2.67345 3.56409e-06H29.3236C29.675 -0.000570329 30.0231 0.0681678 30.3478 0.202282C30.6726 0.336397 30.9678 0.533254 31.2164 0.781583C31.465 1.02991 31.6621 1.32484 31.7966 1.64947C31.9311 1.97411 32.0002 2.32208 32 2.67346V29.3236C32.0004 29.6751 31.9315 30.0232 31.7972 30.348C31.6629 30.6727 31.4659 30.9679 31.2175 31.2165C30.969 31.465 30.674 31.6622 30.3493 31.7966C30.0246 31.9311 29.6765 32.0002 29.3251 32H2.67345C2.32225 32 1.97449 31.9308 1.65003 31.7964C1.32558 31.6619 1.03079 31.4649 0.782523 31.2165C0.53425 30.968 0.337356 30.6732 0.203087 30.3486C0.0688193 30.0241 -0.000190684 29.6763 3.95723e-07 29.3251V2.67346ZM12.6662 12.2007H16.9993V14.3767C17.6247 13.1258 19.2247 12 21.6291 12C26.2385 12 27.3309 14.4916 27.3309 19.0633V27.5316H22.6662V20.1047C22.6662 17.5011 22.0407 16.032 20.4524 16.032C18.2487 16.032 17.3324 17.616 17.3324 20.1047V27.5316H12.6662V12.2007ZM4.66618 27.3324H9.33236V12H4.66618V27.3309V27.3324ZM10 6.99928C10.0088 7.3988 9.93771 7.79606 9.79089 8.16773C9.64408 8.53941 9.42451 8.87802 9.14506 9.16369C8.86562 9.44935 8.53193 9.67633 8.16357 9.83129C7.79522 9.98624 7.39962 10.0661 7 10.0661C6.60038 10.0661 6.20478 9.98624 5.83643 9.83129C5.46808 9.67633 5.13438 9.44935 4.85494 9.16369C4.57549 8.87802 4.35592 8.53941 4.20911 8.16773C4.06229 7.79606 3.9912 7.3988 4 6.99928C4.01727 6.21506 4.34092 5.46879 4.90165 4.92027C5.46238 4.37176 6.2156 4.06461 7 4.06461C7.7844 4.06461 8.53762 4.37176 9.09835 4.92027C9.65908 5.46879 9.98273 6.21506 10 6.99928Z"
        fill=""
      />
    </svg>
  );
};
